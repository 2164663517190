import InputCustom from '@/components/InputCustom';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import React, { useCallback, useMemo, useState } from 'react';
import { LoginBox, LoginCheckbox, LoginContainer, LoginForm } from './style';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import { LockIcon, UserIcon } from '@/components/SvgIcons';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ILogin } from '@/types/interface';
import { useForm } from 'react-hook-form';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import useLogin from '@/queries/useLogin';
import { handleLocalStorage } from '@/utils/handleLocalStorage';
import useStoreApp from '@/store';
import { IconButton } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import parsePhoneNumber, { isValidPhoneNumber } from 'libphonenumber-js';

const CryptoJS = require('crypto-js');

export default function Login() {
  const { t } = useTranslation(['validate', 'common']);
  const { mutate, status } = useLogin();
  const [showPassword, setShowPassword] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const { setAccessToken, hotline } = useStoreApp(state => state.globalSlice);

  const schema = useMemo(
    () =>
      yup.object().shape({
        phone: yup
          .string()
          .trim()
          .required('requiredPhone')
          .test('isValidPhone', 'invalidPhone', (value: string) => {
            const parsedPhone = parsePhoneNumber(value, 'VN');

            return parsedPhone && parsedPhone.isValid();
          }),
        password: yup.string().required('requiredPassword'),
        saveAccount: yup.boolean(),
      }),
    [],
  );

  const account = useMemo(() => {
    {
      const data = handleLocalStorage.get('account');
      if (data) {
        const bytes = CryptoJS.AES.decrypt(
          data.password,
          process.env.NEXT_PUBLIC_CRYPTOJS_SECRET,
        );
        const password = bytes.toString(CryptoJS.enc.Utf8);
        return {
          ...data,
          password,
        };
      }
      return data;
    }
  }, []);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<ILogin & { saveAccount: boolean }>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: { ...account },
  });

  const onSubmit = async (formData: ILogin & { saveAccount: boolean }) => {
    const { saveAccount, ...body } = formData;

    mutate(
      { ...body },
      {
        onSuccess(data: any) {
          if (data) {
            handleLocalStorage.set('accessToken', data?.accessToken);
            setAccessToken(data?.accessToken);

            if (saveAccount) {
              const hashedPassword = CryptoJS.AES.encrypt(
                formData.password,
                process.env.NEXT_PUBLIC_CRYPTOJS_SECRET,
              ).toString();

              handleLocalStorage.set('account', {
                ...formData,
                password: hashedPassword,
              });
            }

            window.location.reload();
          }
        },
        onError(error: any) {
          if (
            error?.response?.data?.message ===
              'Số điện thoại hoặc mật khẩu không đúng' ||
            error?.response?.data?.message === 'Tài khoản hoặc mật khẩu bị sai'
          ) {
            setErrorMsg(`${t('validate:incorrectAccount')}!`);
          } else {
            setErrorMsg(`${t('common:login')} ${t('common:failed')}!`);
          }
        },
      },
    );
  };

  const makeACall = useCallback(() => {
    window.open(`tel:${hotline}`, '_self');
  }, [hotline]);

  const handleClickShowPassword = () => setShowPassword(show => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  return (
    <LoginContainer>
      <LoginBox>
        <Typography component="h2">{t('common:login')}</Typography>
        <LoginForm onSubmit={handleSubmit(onSubmit)}>
          <InputCustom
            type="tel"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <UserIcon />
                </InputAdornment>
              ),
            }}
            inputProps={{
              inputMode: 'numeric',
            }}
            autoComplete="off"
            placeholder={t('common:phone')! + ' *'}
            register={register('phone')}
            error={Boolean(errors.phone)}
            helperText={
              errors.phone?.message && t(`validate:${errors.phone?.message}`)
            }
            disabled={status === 'loading'}
          />

          <InputCustom
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    sx={{ mt: '0px !important' }}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            type={showPassword ? 'text' : 'password'}
            autoComplete="off"
            placeholder={t('common:password')! + ' *'}
            register={register('password')}
            error={Boolean(errors.password)}
            helperText={
              errors.password?.message &&
              t(`validate:${errors.password?.message}`)
            }
            disabled={status === 'loading'}
          />

          <LoginCheckbox
            control={
              <Checkbox
                defaultChecked={Boolean(account?.saveAccount)}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  if (!event.target.checked) {
                    handleLocalStorage.remove('account');
                  }
                }}
              />
            }
            label={t('common:rememberMe')!}
            disabled={status === 'loading'}
            {...register('saveAccount')}
          />

          <Button
            type="submit"
            variant="contained"
            disabled={status === 'loading'}
          >
            {status === 'loading' ? (
              <CircularProgress size={30} />
            ) : (
              t('common:login')
            )}
          </Button>

          <Typography
            component="p"
            sx={{
              mt: '8px',
              fontSize: '14px',
              lineHeight: '16px',
              fontWeight: 400,
              color: '#EA5455',
              textAlign: 'center',
            }}
          >
            {errorMsg}
          </Typography>
        </LoginForm>

        <Typography component="p" className="hotline">
          Hotline: <span onClick={makeACall}>{hotline}</span>
        </Typography>
      </LoginBox>
    </LoginContainer>
  );
}
