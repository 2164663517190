import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';

export const LoginContainer = styled(Box)(({ theme }: any) => ({
  display: 'flex',
  justifyContent: 'flex-end',
}));

export const LoginBox = styled(Box)(({ theme }: any) => ({
  width: '400px',
  minHeight: '390px',
  borderRadius: '16px',
  padding: '32px 24px',
  marginTop: '110px',
  backgroundColor: theme.palette.white.main,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  h2: {
    fontSize: '20px',
    lineHeight: '23px',
    fontWeight: 400,
    marginBottom: '24px',
  },

  '.hotline': {
    marginTop: '32px',
    fontSize: '16px',
    lineHeight: '19px',
    fontWeight: 400,
    color: theme.palette.black[20],

    span: {
      fontWeight: 500,
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
  },
}));

export const LoginForm = styled('form')(({ theme }: any) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  width: '100%',

  button: {
    marginTop: '13px',
  },
}));

export const LoginCheckbox = styled(FormControlLabel)(({ theme }: any) => ({
  width: '148px',
  height: '18px',
  margin: '0px',
  marginTop: '8px',

  '.MuiCheckbox-root': {
    marginRight: '13px',
    padding: '0px',
    height: '18px',
    width: '18px',

    svg: {
      padding: '0px',
    },
  },

  'MuiTypography-root': {
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: 400,
    color: theme.palette.black[10],
  },
}));
