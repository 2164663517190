import Layout from '@/components/Layout';
import { ReactElement } from 'react';
import Login from '@/containers/Login';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

const LoginPage = () => {
  return <Login />;
};

LoginPage.getLayout = (page: ReactElement) => (
  <Layout hasBackground={true}>{page}</Layout>
);
LoginPage.type = 'public';

export async function getServerSideProps({ locale }: { locale: string }) {
  return {
    props: {
      ...(await serverSideTranslations(locale ?? 'en', ['validate', 'common'])),
      // Will be passed to the page component as props
    },
  };
}

export default LoginPage;
