import { authService } from '@/services/authService';
import { ILogin } from '@/types/interface';
import { useMutation } from 'react-query';

export default function useLogin() {
  return useMutation(async (body: ILogin) => {
    const { data } = await authService.login(body);
    return data;
  });
}
